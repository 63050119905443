import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Box from "./singleselect";
import Utils from "../utils/utils.js";

function App({ input, onChange }) {
  // console.log({ input });
  const [boxes, setBoxes] = useState([]);
  // const [selected, setSelected] = useState([]);
  const [master, setMaster] = useState([]);
  const [once, setOnce] = useState(false);
  // console.log({ boxes });
  // console.log(JSON.stringify(boxes));
  // console.log(JSON.stringify(input.segments));

  useEffect(() => {
    // console.log("running");
    // let inp = Object.assign({}, input);
    // //
    // inp.segments = boxes;
    if (JSON.stringify(boxes) !== JSON.stringify(input.segments))
      if (!once) {
        setOnce(true);
        setBoxes(input.segments);
      }
    // onChange(inp);
  }, [input]);

  function calc() {
    // console.log({ boxes, master });
    let allsegs = [];
    boxes.forEach((z) => (allsegs = allsegs.concat(z.data)));
    // console.log({ allsegs });
    let newallsegs = [];
    allsegs.forEach((item, i) => {
      let iz = item.split(",");
      let obj = {};
      iz.forEach((zitem, i) => {
        obj[zitem.substr(0, 1)] = zitem;
      });
      newallsegs.push(obj);
    });
    // console.log({ newallsegs });
    let res = [];
    newallsegs.forEach((item, i) => {
      let ky = Object.keys(item);

      let filt = Object.assign([], master);
      ky.forEach((zitem, i) => {
        filt = filt.filter((z) => z[zitem] === item[zitem]);
      });
      res = res.concat(filt);
    });
    res = res.map((z) => JSON.stringify(z));
    res = [...new Set(res)];
    res = res.map((z) => JSON.parse(z));
    // console.log({ res });

    //
    if (JSON.stringify(boxes) !== JSON.stringify(input.segments)) {
      let inp = Object.assign({}, input);
      inp.segments = boxes;
      onChange(inp);
    }

    return res.reduce((a, b) => (a = a + b.n), 0);
  }

  function prettynumber(number) {
    if (number > 1000000) return (number / 1000000).toFixed(1) + "m";
    else if (number > 1000) return (number / 1000).toFixed(1) + "k";
    else return number + "";
  }

  async function getmaster() {
    let segs = await fetch(
      "https://ba4lokxtk4aj2e4r5kll75c3ka0lfcvc.lambda-url.af-south-1.on.aws/"
    );
    segs = await segs.json();
    // console.log({ segs });
    function reformat(kv) {
      let res = { n: kv.n };
      kv.ids.split(",").forEach((z) => {
        res[z.substr(0, 1)] = z;
      });
      if (!res.M) res.M = "M0";
      if (!res.S) res.S = "S0";
      return res;
    }

    let keys = Object.keys(segs)

      .map((z) => {
        return { ids: z, n: parseInt(segs[z]) };
      })
      .map(reformat);

    setMaster(keys);
  }

  useEffect(() => {
    getmaster();
  }, []);

  useEffect(() => {
    calc();
  }, [boxes]);
  return (
    <div>
      <Button
        onClick={() => {
          let b = Object.assign([], boxes);
          b.push({ id: Utils.create_UUID(), data: [] });
          setBoxes(b);
        }}
      >
        add targeting group
      </Button>
      <h4>Selected target total reach: {prettynumber(calc())}</h4>

      {boxes.map((z) => (
        <div key={z.id} style={{}}>
          <Box
            onChange={(c) => {
               // console.log({ c, id: z.id });
              let sel = Object.assign([], boxes);
              let found = sel.find((a) => a.id === z.id);
              found.data = c;
              setBoxes(sel);
            }}
            gotMaster={(m) => {
              setMaster(m);
            }}
            values={z.data}
          />
          <Button
            onClick={() => {
              setBoxes(boxes.filter((a) => a.id !== z.id));
            }}
          >
            delete above target group
          </Button>
        </div>
      ))}
    </div>
  );
}
export default App;
