import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
// import Logo from "./images/logo512.png";
import HomeIcon from "@material-ui/icons/Home";
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
// import Utils from "./utils/utils";
import Amplify, { Hub, Auth } from "aws-amplify";
import BuildVersion from "../buildversion.json"
import { withOAuth } from "aws-amplify-react";
import MenuIcon from "@material-ui/icons/Menu";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import { withRouter } from "react-router-dom";
import BKG from "./logowhitesqr512.png"
import awsconfig from "../aws-exports";
awsconfig.oauth.domain = "auth.vnamobi.com";
awsconfig.oauth.scope = [
  "phone",
  "email",
  "openid",
  "profile",
  "aws.cognito.signin.user.admin",
];
awsconfig.oauth.responseType = "code";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  awsconfig.oauth.redirectSignIn = "https://localhost:3000/";
  awsconfig.oauth.redirectSignOut = "https://localhost:3000/";
} else {
  awsconfig.oauth.redirectSignIn = "https://console.vnamobi.com/";
  awsconfig.oauth.redirectSignOut = "https://console.vnamobi.com/";
}
Amplify.configure(awsconfig);
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      userName: "",
      authState: "loading",
      authData: null,
      authError: null,
      mobinavopen: false,
      drawer: false,
      search: "",
      profilepic: "",
    };
    this.signOut = this.signOut.bind(this);
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          this.setState({ authState: "signedIn", authData: data.payload.data });
          this.componentDidMount();

          break;
        case "signIn_failure":
          this.setState({
            authState: "signIn",
            authData: null,
            authError: data.payload.data,
          });
          break;
        default:
          break;
      }
    });
  }
  update = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  signOut() {
    Auth.signOut()
      .then(() => {
        this.setState({ authState: "signIn" });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  componentDidMount() {
    window.addEventListener("resize", this.update);
    this.update();
    if (
      this.props.location.search !== "" &&
      this.props.location.search.indexOf("?code=") === -1 &&
      this.props.location.search.indexOf("?id=") === -1
    ) {
      let search = this.props.location.search
        .replace("?search=", "")
        .replace(/%20/g, " ")
        .split(" ")
        .map((z) => (z = z.trim().toLowerCase()));
      this.setState({ search });
    }
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({
          authState: "signedIn",
          userName: user.attributes.email,
        });
        this.props.authState({ state: "signedIn", usr: user.username });
        // console.log(user)
        // this.findUser(user.username);
      })
      .catch((e) => {
        this.setState({ authState: "signIn" });
      });
  }

  render() {
    return (
      <div>
        <Drawer
          anchor={"left"}
          open={this.state.drawer}
          onClose={() => this.setState({ drawer: false })}
        >
          <div style={{ width: "250px" }}>
            <img alt="" src={BKG} style={{ width: "250px" }}/>
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                this.props.history.push("/preview");

                this.setState({ drawer: false });
              }}
            >
              <div
                style={{
                  color: "#8d6744",
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10%",
                  marginRight: "10%",
                  width: "100%",
                }}
              >
                <PhoneAndroidIcon />
                Preview Phone
              </div>
            </Button>

            <Button
              style={{ width: "100%" }}
              onClick={() => {
                this.props.history.push("/manage");

                this.setState({ drawer: false });
              }}
            >
              <div
                style={{
                  color: "#8d6744",
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10%",
                  marginRight: "10%",
                  width: "100%",
                }}
              >
                <TrackChangesIcon />
                Manage
              </div>
            </Button>

            <Button
              style={{ width: "100%" }}
              onClick={() => {
                this.props.history.push("/admin");

                this.setState({ drawer: false });
              }}
            >
              <div
                style={{
                  color: "#8d6744",
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10%",
                  marginRight: "10%",
                  width: "100%",
                }}
              >
                <SupervisorAccountIcon />
                Admin
              </div>
            </Button>
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                this.props.history.push("/segmentupload");

                this.setState({ drawer: false });
              }}
            >
              <div
                style={{
                  color: "#8d6744",
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10%",
                  marginRight: "10%",
                  width: "100%",
                }}
              >
                <GroupWorkIcon />
                Segmentation
              </div>
            </Button>
          </div>

          {this.state.authState === "signedIn" ? (
            <Button
              color="inherit"
              onClick={() => Auth.signOut()}
              style={{ color: "#8d6744", width: "100%" }}
            >
              <div
                style={{
                  color: "#8d6744",
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10%",
                  marginRight: "10%",
                  width: "100%",
                }}
              >
                <ExitToAppIcon />
                Logout
              </div>
            </Button>
          ) : null}
          <div style={{fontSize:14, marginTop:20,color:"#666",alignSelf:"center"}}>Build Version {BuildVersion.version}</div>
        </Drawer>
        <AppBar position="static">
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#ffbb7b",
            }}
          >
            <div style={{display:"flex", flexDirection:"row"}}>
              <Button onClick={() => this.setState({ drawer: true })}>
                <MenuIcon style={{ color: "#8d6744" }} />
              </Button>
              {this.state.width > 800 ? (
                <div>
                <Button
                  onClick={() => {
                    this.props.history.push("/");
                    window.location.reload();
                  }}
                >
                  <HomeIcon style={{ color: "#8d6744" }} />
                </Button>
                <Button
                  onClick={() => {
                    this.props.history.push("/manage");
                    // window.location.reload();
                  }}
                  >
                  <TrackChangesIcon style={{ color: "#8d6744" }} />
                </Button>
              </div>
              ) : null}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                marginLeft: "5%",
              }}
            >
              {true ? null : (
                <InputBase
                  placeholder="Search Orders"
                  inputProps={{ "aria-label": "search " }}
                  style={{ color: "#8d6744", width: "100%" }}
                  onKeyDown={(key) => {
                    if (key.keyCode === 13) {
                      this.props.history.push("/?search=" + this.state.search);
                      window.location.reload();
                    }
                  }}
                  onChange={(search) =>
                    this.setState({ search: search.target.value })
                  }
                  value={this.state.search}
                />
              )}
              {true ? null : (
                <IconButton
                  type="submit"
                  aria-label="search"
                  onClick={() => {
                    this.props.history.push("/?search=" + this.state.search);
                    window.location.reload();
                  }}
                >
                  <SearchIcon style={{ color: "#8d6744" }} />
                </IconButton>
              )}
            </div>

            <div style={{ color: "#8d6744" }}>
              {this.state.authState === "signedIn" ? (
                <Button
                  style={{ color: "#8d6744", textTransform: "none" }}
                  onClick={() => this.props.history.push("/profile")}
                >
                  <Avatar
                    style={{ marginRight: 10 }}
                    alt={this.state.userName}
                    src={this.state.profilepic}
                  />{" "}
                  {this.state.width > 800 ? this.state.userName : null}
                </Button>
              ) : null}
              {this.state.authState !== "signedIn" ? (
                <Button
                  color="inherit"
                  onClick={this.props.OAuthSignIn}
                  style={{ color: "#8d6744" }}
                >
                  Login
                </Button>
              ) : null}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withOAuth(withRouter(Header));
