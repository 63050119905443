import React from "react";
import { withRouter } from "react-router-dom";
import OrderDetail from "../components/phonesim";
import Snackbar from "@material-ui/core/Snackbar";
import Logo from "./logotrans.png"
class ClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: this.props.authState,
      edit:false,
      orders: [],
      idnumber: 0,
      campaigns: [],
      segments: [{id:1,name:"select", total:0}],
      segmentscombo: [],
      orderdata: {
        active: false,
        dayhourindicator: [
          // { date: "2020-09-25", impressions: 100, hours: [1, 2, 3, 4, 23] ,id:1, done:true},
          // { date: "2020-09-26", impressions: 100, hours: [1, 2, 3, 4, 23] ,id:2, done:false},
          // { date: "2020-09-27", impressions: 100, hours: [1, 2, 3, 4, 23] ,id:3, done:false},
        ],
        frequency: 99,
        id: "",
        name: "",
        vNAOrdersCampaignsId: "",

        segments: [],
        text: "",
        uriimage: null,
        urlredirect: "https://google.com",
      },
    };
  }
  // componentDidUpdate(prevProps) {
  //   if (prevProps.authState.state !== this.props.authState.state) {
  //     this.setState({ authState: this.props.authState });
  //     this.getCampaign();
  //     this.getorders();
  //   }
  // }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  //
  // componentDidMount() {
  //   setTimeout(() => {
  //     if (this.state.authState.state !== "signedIn")
  //       this.props.history.push("/");
  //     else {
  //       this.getorders();
  //       this.getCampaign();
  //     }
  //   }, 1000);
  // }
  //
  // async getorders() {
  //   this.doSnackBar("getting data");
  //   let ord = await Utils.genericList("listVnaOrderss", {});
  //   if (ord.data.listVNAOrderss.items.length === 0)
  //     this.setState({ idnumber: 0 });
  //   else {
  //     let max = -1;
  //     ord.data.listVNAOrderss.items.forEach((item, i) => {
  //       if (parseInt(item.id) > max) max = parseInt(item.id);
  //     });
  //     max += 1;
  //     this.setState({ idnumber: max });
  //   }
  //   let segments = await Utils.doGet("allsegments");
  //   let segmentscombo = await Utils.doGet("allsegmentscombo");
  //   console.log({ord})
  //   if (ord) this.setState({ orders: ord.data.listVNAOrderss.items, segments ,segmentscombo});
  // }
  // async addOrder(item) {
  //
  //   if (item.uriimage !== null && item.uriimage.indexOf("public") === -1) {
  //     this.doSnackBar("uploading picture");
  //
  //     let picuri = await Utils.savetos3(item.uriimage);
  //     item.uriimage = picuri.key;
  //   }
  //   item.id = "" + this.state.idnumber;
  //   item.dayhourindicator = JSON.stringify(item.dayhourindicator);
  //   item.segments = JSON.stringify(item.segments);
  //   await Utils.genericMutation("createVnaOrders", item);
  //   this.doSnackBar("Added order");
  //   this.getorders();
  // }
  // async updateOrder(item) {
  //   if (item.uriimage !== null && item.uriimage.indexOf("public") === -1) {
  //     this.doSnackBar("uploading picture");
  //
  //     let picuri = await Utils.savetos3(item.uriimage);
  //     item.uriimage = picuri.key;
  //   }
  //   item.dayhourindicator = JSON.stringify(item.dayhourindicator);
  //   item.segments = JSON.stringify(item.segments);
  //
  //   await Utils.genericMutation("updateVnaOrders", item);
  //   this.doSnackBar("Added updated");
  //   this.getorders();
  // }
  // async getCampaign() {
  //   let camp = await Utils.genericList("listVnaCampaignss", {});
  //   // console.log(camp);
  //   if (camp) this.setState({ campaigns: camp.data.listVNACampaignss.items });
  // }
  // async delOrder(id) {
  //   this.doSnackBar("deleting");
  //   await Utils.genericMutation("deleteVnaOrders", { id });
  //   this.componentDidMount();
  // }
  render() {
    return (
      <div style={{ margin: 20, color: "#666" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
      <img alt="" src={Logo} style={{alignItems:"center", width:"50%", marginLeft:"25%", marginTop:100, marginBottom:300, }}/>
        <h1>Preview</h1>


        <OrderDetail
          input={this.state.orderdata}
          segments={this.state.segments}
          allsubseg={this.state.segmentscombo}
          campaigns={this.state.campaigns}
          onChange={(change) => this.setState({ orderdata: change })}
        />

      </div>
    );
  }
}
export default withRouter(ClientPage);
