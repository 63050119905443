/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVnaLabel = /* GraphQL */ `
  query GetVnaLabel($id: ID!) {
    getVNALabel(id: $id) {
      id
      name
      clients {
        items {
          id
          name
          campaigns{
            name
            id
            items{
              name
              id
              orders{
                items {
                  active

                  id
                  name
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVnaLabels = /* GraphQL */ `
  query ListVnaLabels(
    $filter: ModelVNALabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVNALabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        clients(limit:1000) {
          items {
            id
            name
            campaigns(limit:1000) {
              items {
                id
                name
                orders(limit:1000, filter: {id: {gt: "3", size: {ge: 3}}}) {
                  items {
                    id
                    name
                    dayhourindicator
                    segments
                    SMS
                    text
                    uriimage
                    urlsenttoftp
                    active
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVnaClient = /* GraphQL */ `
  query GetVnaClient($id: ID!) {
    getVNAClient(id: $id) {
      id
      name
      label {
        id
        name
        clients {
          nextToken
        }
        createdAt
        updatedAt
      }
      campaigns {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVnaClients = /* GraphQL */ `
  query ListVnaClients(
    $filter: ModelVNAClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVNAClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        label {
          id
          name
          createdAt
          updatedAt
        }
        campaigns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVnaCampaigns = /* GraphQL */ `
  query GetVnaCampaigns($id: ID!) {
    getVNACampaigns(id: $id) {
      id
      name
      client {
        id
        name
        label {
          id
          name
          createdAt
          updatedAt
        }
        campaigns {
          nextToken
        }
        createdAt
        updatedAt
      }
      orders {
        items {
          id
          active
          name
          frequency
          dayhourindicator
          segments
          SMS
          smsoptions
          uriimage
          billing
          urlredirect
          text
          label
          urlsenttoftp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVnaCampaignss = /* GraphQL */ `
  query ListVnaCampaignss(
    $filter: ModelVNACampaignsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVNACampaignss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        client {
          id
          name
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVnaOrders = /* GraphQL */ `
  query GetVnaOrders($id: ID!) {
    getVNAOrders(id: $id) {
      id
      active
      name
      campaigns {
        id
        name
        client {
          id
          name
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      frequency
      dayhourindicator
      segments
      uriimage
      SMS
      smsoptions
      billing
      urlredirect
      text
      label
      urlsenttoftp
      createdAt
      updatedAt
    }
  }
`;
export const listVnaOrderss = /* GraphQL */ `
  query ListVnaOrderss(
    $filter: ModelVNAOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVNAOrderss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        name
        campaigns {
          id
          name
          createdAt
          updatedAt
        }
        frequency
        dayhourindicator
        segments
        uriimage
        SMS
        smsoptions
        billing
        urlredirect
        text
        label
        urlsenttoftp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVnaUsers = /* GraphQL */ `
  query GetVnaUsers($id: ID!) {
    getVNAUsers(id: $id) {
      id
      name
      email
      labels
      rights
      createdAt
      updatedAt
    }
  }
`;
export const listVnaUserss = /* GraphQL */ `
  query ListVnaUserss(
    $filter: ModelVNAUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVNAUserss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        labels
        rights
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVnaCommands = /* GraphQL */ `
  query GetVnaCommands($id: ID!) {
    getVNACommands(id: $id) {
      id
      commandpostdate
      done
      command
      createdAt
      updatedAt
    }
  }
`;
export const listVnaCommandss = /* GraphQL */ `
  query ListVnaCommandss(
    $filter: ModelVNACommandsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVNACommandss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        commandpostdate
        done
        command
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
