import React from "react";
import { withRouter, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import ErrorIcon from "@material-ui/icons/Error";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import Utils from "../utils/utils";

import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import PreviewImage from "../components/previewimage";
import Tooltip from "@material-ui/core/Tooltip";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import XLSX from "xlsx";
import ProgressBar from "@ramonak/react-progress-bar";
class ClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: this.props.authState,
      orders: [],
      labels: [],
      pureLabels: [],
      none: [],
      donecheck: false,
      selectedLabel: "",
      selectedClient: "",
      newClientName: "",
      newCampaignName: "",
      selectedClientDisabled: true,
      selectedCampaignDisabled: true,
      selectedCampaign: "",
      openlabels: false,
      opencamp: false,
      openlabelsUser: null,
      newLabelName: "",
      hideInactive: true,
      deletePopup: false,
      deletePopupid: null,
      liveCounters: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.authState.state !== this.props.authState.state) {
      this.setState({ authState: this.props.authState });

      this.getClients();
    }
  }

  async updateLiveCounter() {
    // console.log(this.state.liveCounters);
    let live = this.state.liveCounters;
    let today = new Date(Date.now());

    today.setHours(today.getHours() + 2);
    today = today.toISOString().substr(0, 10);
    // console.log(today);

    async function t(input) {
      // console.log(input)
      let key = "livecounterorder" + input.orderid + today;
      // console.log(key)
      try {
        let response = await fetch(
          "https://wu6xte3axytx444d5erlk2o52u0dvbwq.lambda-url.af-south-1.on.aws/",
          {
            method: "POST",
            mode: "cors",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
              AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
              Accept: "application/json",
            },
            body: JSON.stringify({ get: true, key: key }),
          }
        );

        let s = { orderid: input.orderid, value: await response.json() };
        // console.log({s})
        return s;
      } catch (err) {
        console.log("err");
        return 0;
      }
      // input.value = await Utils.doGet(key);
      // // console.log(input)
      // return input;
    }

    if (live.length > 0) {
      let r = await Promise.all(live.map(t));
      // console.log(r);
      this.setState({ liveCounters: r });
    }
  }

  collectLiveImpressionString(orderid) {
    let live = this.state.liveCounters;
    // console.log({ live });
    let res = live.find((z) => z.orderid === "" + orderid);
    let val = 0;
    if (res === undefined) val = 0;
    else {
      if (res.val !== null && res.val !== "") val = res.value;
    }

    if (val === "") val = 0;
    return val;
    // let val = res.value;
    // else return val;

    // if (val > 1000) val = (val / 1000).toFixed(0) + "k";
    // res.value > 0
    //  ? res.value > 1000
    //    ? (res.value / 1000).toFixed(0) + "k"
    //    : res.value.toFixed(0) + ""
    //  : ""
    // console.log(res.value)
    // return res.value}
  }

  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  async getBQ(type, id) {
    this.doSnackBar("Downloading");
    // console.log({ type, id });
    let s = await fetch(
      "https://luqkgkokq4.execute-api.eu-west-1.amazonaws.com/production/getorderstats?type=" +
        type +
        "&id=" +
        id
    );
    let res = await s.json();
  //  console.log(res);
    res = res.sort((a, b) => {
      if (a.date > b.date) return 1;
      else if (a.date < b.date) return -1;
      else return 0;
    });

    res.forEach((z) => {
      let key = "";
      if (z.label !== undefined) {
        key += z.label.trim() + ", ";
        delete z.label;
      }
      if (z.client !== undefined) {
        key += z.client.trim() + ", ";
        delete z.client;
      }
      if (z.campaign !== undefined) {
        key += z.campaign.trim() + ", ";
        delete z.campaign;
      }
      if (z.order !== undefined) {
        key += z.order.trim();
        delete z.order;
      }
      z[key] = z.impressions;
      delete z.impressions;
      delete z.orderno;
    });
    // console.log(res);

    let pivot = [];
    res.forEach((z) => {
      let ky = Object.keys(z)[2]
      let found = pivot.find((a) => a.date === z.date && a[ky] === z[ky]);
      if (found) {
        let newkey = Object.keys(z).filter((q) => q !== "date")[0];
        found[newkey] = z[newkey];
      } else pivot.push(z);
    });
    // console.log(pivot);
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(pivot);
    XLSX.utils.book_append_sheet(wb, ws, "Impressions");
    XLSX.writeFile(wb, "impressions.xlsx");
  }

  async addNewCampaign(clientid, campname) {
    // console.log({clientid})
    // console.log({campname})
    this.doSnackBar("Adding new campaign");
    let data = {
      name: campname,
      vNACampaignsClientId: clientid,
    };
    let newclient = await Utils.genericMutation("createVnaCampaigns", data);
    // console.log({newclient})
    let userrights = await Utils.genericGet(
      "getVnaUsers",
      this.state.authState.usr
    );
    let labels = {};
    if (userrights.data.getVNAUsers.labels !== "*")
      labels = await Utils.genericList("listVnaLabels", {
        filter: { id: { eq: userrights.data.getVNAUsers.labels } },
      });
    else labels = await Utils.genericList("listVnaLabels", {});
    this.setState({
      opencamp: false,
      selectedCampaign: newclient.data.createVNACampaigns.id,
      pureLabels: labels.data.listVNALabels.items,
    });
    this.doSnackBar("Added campaign");
  }
  async addNewClient(label, clientname) {
    this.doSnackBar("Adding new Client");
    let data = {
      name: clientname,
      vNAClientLabelId: this.state.selectedLabel,
    };
    let newclient = await Utils.genericMutation("createVnaClient", data);
    let userrights = await Utils.genericGet(
      "getVnaUsers",
      this.state.authState.usr
    );
    let labels = {};
    if (userrights.data.getVNAUsers.labels !== "*")
      labels = await Utils.genericList("listVnaLabels", {
        filter: { id: { eq: userrights.data.getVNAUsers.labels } },
      });
    else labels = await Utils.genericList("listVnaLabels", {});
    this.setState({
      openlabels: false,
      selectedClient: newclient.data.createVNAClient.id,
      pureLabels: labels.data.listVNALabels.items,
    });
    this.doSnackBar("Added Client");
  }
  componentDidMount() {
    setTimeout(() => {
      if (this.state.authState.state !== "signedIn")
        this.props.history.push("/");
      else this.getClients();
    }, 1000);
    setInterval(this.updateLiveCounter.bind(this), 10000);
  }
  async toggleACtiveOrder(id) {
    this.doSnackBar("Busy...");
    let userrights = await Utils.genericGet("getVnaOrders", id);
    let active = !userrights.data.getVNAOrders.active;
    await Utils.genericMutation("updateVnaOrders", { id, active });
    let labels = this.state.labels;
    let found = labels.find((z) => z.orderid === id);
    if (found) found.active = active;
    this.setState({ labels });
    this.doSnackBar("Done");
  }
  getClientsSelectionList(selectedLabel, alldata) {
    // console.log({ alldata });
    if (alldata.length > 0 && selectedLabel !== "") {
      let result = alldata.filter((z) => z.id === selectedLabel)[0].clients
        .items;
      if (result.filter((z) => z.id === "z").length === 0)
        result.push({
          id: "z",
          name: "Add new client",
          labelid: selectedLabel,
        });

      return result;
    } else return [];
  }
  getCampaignSelectionList(label, selectedclient, alldata) {
    // console.log({ alldata });
    // console.log({ label });
    // console.log({ selectedclient });
    if (alldata.length > 0 && label !== "" && selectedclient !== "") {
      let result = alldata.filter((z) => z.id === label)[0].clients.items;
      // console.log({ result });
      result = result.filter((z) => z.id === selectedclient)[0].campaigns.items;
      // console.log({ result });
      if (result.filter((z) => z.id === "z").length === 0)
        result.push({
          id: "z",
          name: "Add new campaign",
          clientid: label,
        });

      return result;
    } else return [];
  }
  async getClients() {
    this.doSnackBar("Collecting data");
    while (this.state.authState.usr !== "" && !this.state.donecheck) {
      let userrights = await Utils.genericGet(
        "getVnaUsers",
        this.state.authState.usr
      );
      this.setState({ donecheck: true });
      let labels = {};
      if (userrights.data.getVNAUsers.labels !== "*")
        labels = await Utils.genericList("listVnaLabels", {
          filter: { id: { eq: userrights.data.getVNAUsers.labels } },
        });
      else labels = await Utils.genericList("listVnaLabels", {});

      let flatLabels = [];
      function randomstring(L) {
        var s = "";
        var randomchar = function () {
          var n = Math.floor(Math.random() * 62);
          if (n < 10) return n; //1-10
          if (n < 36) return String.fromCharCode(n + 55); //A-Z
          return String.fromCharCode(n + 61); //a-z
        };
        while (s.length < L) s += randomchar();
        return s;
      }

      labels.data.listVNALabels.items.forEach((a) => {
        if (a.clients.items.length === 0)
          flatLabels.push({
            key: randomstring(5),
            label: a.name,
            labelid: a.id,
          });
        a.clients.items.forEach((b) => {
          if (b.campaigns.items.length === 0)
            flatLabels.push({
              key: randomstring(5),
              label: a.name,
              labelid: a.id,
              client: b.name,
              clientid: b.id,
            });
          b.campaigns.items.forEach((c) => {

            if (c.orders.items.length === 0)
              flatLabels.push({
                key: randomstring(5),
                label: a.name,
                labelid: a.id,
                client: b.name,
                clientid: b.id,
                campaign: c.name,
                campaignid: c.id,
              });
            c.orders.items.forEach((d) => {

              let dayhourindicator = JSON.parse(d.dayhourindicator);
              let targettotal = 0;
              if (dayhourindicator.length > 0) {
                targettotal = dayhourindicator.reduce(
                  (a, b) => a + parseInt(b.impressionstarget),
                  0
                );
              }
              let targetgroups = JSON.parse(d.segments);
              if (targetgroups === null || targetgroups.length === 0)
                targetgroups = false;
              else targetgroups = true;
              let textin = d.text;
              if (textin === null || textin.length === 0) textin = false;
              else textin = true;
              // console.log(JSON.stringify(d.segments, null, 4));
              // console.log(JSON.stringify(targettotal,null,4))
              let dates = dayhourindicator.map((z) => z.date);
              dates = dates.sort((a, b) => {
                if (a > b) return 1;
                else if (a < b) return -1;
                else return 0;
              });
              let today = new Date(Date.now());

              today.setHours(today.getHours() + 2);
              today = today.toISOString().substr(0, 10);
              // console.log(today)
              let targettoday = 0;
              let progs = dayhourindicator.filter((z) => z.date === today);
              if (progs.length > 0) {
                if (progs[0].impressionstarget > 0) {
                  targettoday = progs[0].impressionstarget;

                  progs = Math.floor(
                    (progs[0].impressionsactual * 100) /
                      progs[0].impressionstarget
                  );
                } else progs = null;
              } else progs = null;

              flatLabels.push({
                key: randomstring(5),
                progs: progs,
                label: a.name,
                SMS:d.SMS,
                labelid: a.id,
                client: b.name,
                clientid: b.id,
                campaign: c.name,
                campaignid: c.id,
                order: d.name,
                startdate: dates[0],
                enddate: dates[dates.length - 1],
                orderid: d.id,
                active: d.active,
                uriimage: d.uriimage,
                urlsenttoftp: d.urlsenttoftp,
                impressions: "",
                targettotal,
                targettoday,
                targetgroups,
                textin,
              });
            });
          });
        });
      });


      this.setState({
        labels: flatLabels,
        pureLabels: labels.data.listVNALabels.items,
        liveCounters: flatLabels
          .filter((z) => z.active)
          .map((z) => {
            return { orderid: z.orderid, value: "" };
          }),
      });
      this.updateImpressions(
        flatLabels.map((z) => z.orderid).filter((z) => z !== undefined)
      );
    }
  }

  async updateImpressions(input) {
    async function gets(num) {
      // let counter = await Utils.doGet("livecounterorder" + num);
      let counter = 0;

      // console.log("livecounterorder" + num)
      try {
        let response = await fetch(
          "https://wu6xte3axytx444d5erlk2o52u0dvbwq.lambda-url.af-south-1.on.aws/",
          {
            method: "POST",
            mode: "cors",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
              AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
              Accept: "application/json",
            },
            body: JSON.stringify({ get: true, key: "livecounterorder" + num }),
          }
        );

        counter = await response.json();
        // console.log({counter})
        if (counter === null) counter = 0;
      } catch (err) {
        console.log("err");
        counter = 0;
      }

      // console.log({ num, counter });
      if (counter === null || counter === undefined) counter = 0;
      return { order: num, count: counter };
    }
    // console.log(input)
    let allgets = await Promise.all(input.map(gets));
    // console.log({allgets})
    let labels = this.state.labels;
    labels.forEach((z) => {
      let found = allgets.find((a) => a.order === z.orderid);
      if (found) z.impressions = found.count;
    });
    this.setState({ labels });
  }

  async deleteOrder(input) {
    this.doSnackBar("Deleteing order");
    // console.log({input})
    await Utils.genericMutation("deleteVnaOrders", { id: input });
    this.setState({ deletePopup: false, deletePopupid: null });

    let userrights = await Utils.genericGet(
      "getVnaUsers",
      this.state.authState.usr
    );
    this.setState({ donecheck: true });
    let labels = {};
    if (userrights.data.getVNAUsers.labels !== "*")
      labels = await Utils.genericList("listVnaLabels", {
        filter: { id: { eq: userrights.data.getVNAUsers.labels } },
      });
    else labels = await Utils.genericList("listVnaLabels", {});
    let flatLabels = [];
    function randomstring(L) {
      var s = "";
      var randomchar = function () {
        var n = Math.floor(Math.random() * 62);
        if (n < 10) return n; //1-10
        if (n < 36) return String.fromCharCode(n + 55); //A-Z
        return String.fromCharCode(n + 61); //a-z
      };
      while (s.length < L) s += randomchar();
      return s;
    }

    labels.data.listVNALabels.items.forEach((a) => {
      if (a.clients.items.length === 0)
        flatLabels.push({
          key: randomstring(5),
          label: a.name,
          labelid: a.id,
        });
      a.clients.items.forEach((b) => {
        if (b.campaigns.items.length === 0)
          flatLabels.push({
            key: randomstring(5),
            label: a.name,
            labelid: a.id,
            client: b.name,
            clientid: b.id,
          });
        b.campaigns.items.forEach((c) => {
          if (c.orders.items.length === 0)
            flatLabels.push({
              key: randomstring(5),
              label: a.name,
              labelid: a.id,
              client: b.name,
              clientid: b.id,
              campaign: c.name,
              campaignid: c.id,
            });
          c.orders.items.forEach((d) => {
            let dayhourindicator = JSON.parse(d.dayhourindicator);

            // console.log(dayhourindicator)
            let dates = dayhourindicator.map((z) => z.date);
            dates = dates.sort((a, b) => {
              if (a > b) return 1;
              else if (a < b) return -1;
              else return 0;
            });
            let today = new Date(Date.now());

            today.setHours(today.getHours() + 2);
            today = today.toISOString().substr(0, 10);
            // console.log(today)
            let progs = dayhourindicator.filter((z) => z.date === today);
            if (progs.length > 0) {
              if (progs[0].impressionstarget > 0) {
                progs = Math.floor(
                  (progs[0].impressionsactual * 100) /
                    progs[0].impressionstarget
                );
              } else progs = null;
            } else progs = null;
            flatLabels.push({
              key: randomstring(5),
              progs: progs,
              label: a.name,
              labelid: a.id,
              client: b.name,
              clientid: b.id,
              campaign: c.name,
              campaignid: c.id,
              order: d.name,
              startdate: dates[0],
              enddate: dates[dates.length - 1],
              orderid: d.id,
              active: d.active,
              uriimage: d.uriimage,
              urlsenttoftp: d.urlsenttoftp,
              impressions: "",
            });
          });
        });
      });
    });

    // console.log({ flatLabels });
    this.setState({
      labels: flatLabels,
      pureLabels: labels.data.listVNALabels.items,
    });
    this.updateImpressions(
      flatLabels.map((z) => z.orderid).filter((z) => z !== undefined)
    );
    this.doSnackBar("Deleted");
  }

  render() {
    return (
      <div style={{ margin: 20, color: "#666" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <Dialog
          onClose={() => this.setState({ deletePopup: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.deletePopup}
        >
          <DialogTitle id="simple-dialog-title">
            Delete Order! Are you sure?
          </DialogTitle>
          <div
            style={{ padding: 10, display: "flex", flexDirection: "column" }}
          >
            <Button
              style={{ marginTop: 10 }}
              color="secondary"
              variant="contained"
              onClick={() => {
                this.deleteOrder(this.state.deletePopupid);
              }}
            >
              Yes Delete!
            </Button>
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              variant="contained"
              onClick={() => this.setState({ deletePopup: false })}
            >
              Cancel
            </Button>
          </div>
        </Dialog>
        <Dialog
          onClose={() => this.setState({ openlabels: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.openlabels}
        >
          <DialogTitle id="simple-dialog-title">Name of new client</DialogTitle>
          <div
            style={{ padding: 10, display: "flex", flexDirection: "column" }}
          >
            <TextField
              label="New Client"
              variant="outlined"
              value={this.state.newClientName}
              onChange={(change) =>
                this.setState({ newClientName: change.target.value })
              }
            />
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              variant="contained"
              disabled={this.state.newClientName.length === 0 ? true : false}
              onClick={() =>
                this.addNewClient(
                  this.state.selectedLabel,
                  this.state.newClientName.trim()
                )
              }
            >
              Add new client
            </Button>
          </div>
        </Dialog>
        <Dialog
          onClose={() => this.setState({ opencamp: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.opencamp}
        >
          <DialogTitle id="simple-dialog-title">
            Name of new campaign
          </DialogTitle>
          <div
            style={{ padding: 10, display: "flex", flexDirection: "column" }}
          >
            <TextField
              label="New Campaign"
              variant="outlined"
              value={this.state.newCampaignName}
              onChange={(change) =>
                this.setState({ newCampaignName: change.target.value })
              }
            />
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              variant="contained"
              disabled={this.state.newCampaignName.length === 0 ? true : false}
              onClick={() =>
                this.addNewCampaign(
                  this.state.selectedClient,
                  this.state.newCampaignName.trim()
                )
              }
            >
              Add new campaign
            </Button>
          </div>
        </Dialog>
        <Paper style={{ padding: 10, background: "#eee", color: "#666" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>Manage</h1>
            <div>
              {this.state.hideInactive
                ? "Show inactive orders"
                : "Hide inactive orders"}
              <Switch
                checked={this.state.hideInactive}
                onChange={() => {
                  this.setState({ hideInactive: !this.state.hideInactive });
                }}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "8%" }}>
                  <b>Label</b>
                </TableCell>
                <TableCell style={{ width: "10%" }}>
                  <b>Client</b>
                </TableCell>
                <TableCell style={{ width: "15%" }}>
                  <b>Campaign</b>
                </TableCell>
                <TableCell style={{ width: "20%" }}>
                  <b>Order</b>
                </TableCell>
                <TableCell style={{ width: "5%" }}>
                  <b>Start/</b>
                  <b>End</b>
                </TableCell>

                <TableCell style={{ width: "1%" }}>
                  <b>Active</b>
                </TableCell>
                <TableCell style={{ width: "10%" }}>
                  <b>Impressions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ background: "#ffbb7b" }}>
                <TableCell style={{ width: "8%" }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Label</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.selectedLabel}
                      onChange={(change) => {
                        this.setState({
                          selectedLabel: change.target.value,
                          selectedClientDisabled: false,
                          selectedCampaign: "",
                          selectedClient: "",
                        });
                      }}
                    >
                      {this.state.pureLabels.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell style={{ width: "10%" }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Client
                    </InputLabel>
                    <Select
                      disabled={this.state.selectedClientDisabled}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.selectedClient}
                      onChange={(change) => {
                        if (change.target.value === "z")
                          this.setState({
                            openlabels: true,
                            selectedCampaignDisabled: false,
                          });
                        else
                          this.setState({
                            selectedClient: change.target.value,
                            selectedCampaignDisabled: false,
                          });
                      }}
                    >
                      {this.getClientsSelectionList(
                        this.state.selectedLabel,
                        this.state.pureLabels
                      ).map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell style={{ width: "15%" }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Campaign
                    </InputLabel>
                    <Select
                      disabled={this.state.selectedCampaignDisabled}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.selectedCampaign}
                      onChange={(change) => {
                        if (change.target.value === "z")
                          this.setState({ opencamp: true });
                        else
                          this.setState({
                            selectedCampaign: change.target.value,
                          });
                      }}
                    >
                      {this.getCampaignSelectionList(
                        this.state.selectedLabel,
                        this.state.selectedClient,
                        this.state.pureLabels
                      ).map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell style={{ width: "20%" }}>
                  <Button
                    variant="contained"
                    disabled={this.state.selectedCampaign === "" ? true : false}
                    color="primary"
                    onClick={() => {
                      this.props.history.push(
                        "/order?campaign=" + this.state.selectedCampaign
                      );
                    }}
                  >
                    Create new order
                  </Button>
                </TableCell>
                <TableCell style={{ width: "5%" }}></TableCell>
                <TableCell style={{ width: "1%" }}></TableCell>
                <TableCell style={{ width: "5%" }}></TableCell>
              </TableRow>
              {this.state.labels
                .filter((z) => (this.state.hideInactive ? z.active : true))
                .filter((z) => z.orderid !== undefined)
                .sort((a, b) => {
                  if (a.enddate > b.enddate) return -1;
                  else if (a.enddate < b.enddate) return 1;
                  else return 0;
                })
                .map((z) => (
                  <TableRow key={z.key}>
                    <TableCell style={{ width: "8%" }}>
                      <div>{z.label}</div>
                      <Button
                        onClick={() => this.getBQ("label", z.labelid)}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        label={{ margin: 0 }}
                      >
                        <AssignmentReturnedIcon style={{ color: "#4db6ac" }} />
                      </Button>
                    </TableCell>
                    <TableCell
                      onClick={() => this.getBQ("client", z.clientid)}
                      style={{ width: "10%" }}
                    >
                      <div>{z.client}</div>
                      <Button
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        label={{ margin: 0 }}
                      >
                        <AssignmentReturnedIcon style={{ color: "#4db6ac" }} />
                      </Button>
                    </TableCell>
                    <TableCell
                      onClick={() => this.getBQ("campaign", z.campaignid)}
                      style={{ width: "15%" }}
                    >
                      <div>{z.campaign}</div>
                      <Button
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        label={{ margin: 0 }}
                      >
                        <AssignmentReturnedIcon style={{ color: "#4db6ac" }} />
                      </Button>
                    </TableCell>

                    <TableCell style={{ width: "20%", padding: 0, margin: 0 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {z.SMS === true ? (
                            <img
                            alt="SMS"
                              src="/sms.jpg"
                              style={{
                                maxWidth: "50px",
                                maxHeight: "80px",
                                display: "block",
                                marginRight: 5,
                              }}
                            />
                          ) : (
                            <PreviewImage image={z.uriimage} />
                          )}
                          {z.order}
                          {z.order ? (
                            <Link to={"/order?order=" + z.orderid}>
                              <Button>
                                <EditIcon />
                              </Button>
                            </Link>
                          ) : null}
                          {!z.targetgroups ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <ErrorIcon style={{ color: "#f00" }} />
                              No segments
                            </div>
                          ) : null}
                          {!z.textin  && z.SMS===false? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <ErrorIcon style={{ color: "#f00" }} />
                              No text
                            </div>
                          ) : null}
                          {!z.uriimage   && z.SMS===false? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <ErrorIcon style={{ color: "#f00" }} />
                              No image
                            </div>
                          ) : null}
                          {!z.targettotal ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <ErrorIcon style={{ color: "#f00" }} />
                              No targets
                            </div>
                          ) : null}
                          {!z.startdate ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <ErrorIcon style={{ color: "#f00" }} />
                              No dates
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            onClick={() => this.getBQ("order", z.orderid)}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            label={{ margin: 0 }}
                          >
                            <AssignmentReturnedIcon
                              style={{ color: "#4db6ac" }}
                            />
                          </Button>
                          {z.SMS===true?<div style={{width:24}}/>:
                          <Tooltip
                            title={
                              z.urlsenttoftp
                                ? "Success FTP image to server"
                                : "Unsuccessful FTP image to server"
                            }
                          >
                            <CloudUploadIcon
                              style={{
                                color: z.urlsenttoftp ? "#009688" : "#e91e63",
                              }}
                            />
                          </Tooltip>
                        }
                          <Button
                            style={{
                              color: z.impressions > 0 ? "#666" : "#e91e63",
                            }}
                            disabled={z.impressions > 0 ? true : false}
                            onClick={() =>
                              this.setState({
                                deletePopup: true,
                                deletePopupid: z.orderid,
                              })
                            }
                          >
                            <DeleteForeverIcon />
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ width: "5%" }}>
                      <div>
                        <div>{z.startdate}</div>
                        <div>{z.enddate}</div>
                      </div>
                    </TableCell>
                    <TableCell style={{ width: "1%" }}>
                      {z.order ? (
                        <Switch
                          checked={z.active ? true : false}
                          onChange={(change) => {
                            this.toggleACtiveOrder(z.orderid);
                          }}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell style={{ width: "5%", margin: 0, padding: 0 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Tooltip
                          placement="top"
                          title={z.impressions + " Total lifetime impressions"}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ width: "20%", fontSize: 10 }}>
                              Total
                            </div>
                            <div style={{ width: "80%" }}>
                              <ProgressBar
                                labelColor="#666"
                                bgcolor="#81c784"
                                labelAlignment="center"
                                completed={parseInt(
                                  (z.impressions /
                                    (z.targettotal === 0 ? 1 : z.targettotal)) *
                                    100
                                )}
                                width="100%"
                              />
                            </div>
                          </div>
                        </Tooltip>
                        <Tooltip
                          placement="top"
                          title={
                            (this.collectLiveImpressionString(z.orderid) ===
                            null
                              ? "0"
                              : this.collectLiveImpressionString(z.orderid)) +
                            " LIVE impressions"
                          }
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "20%",
                                fontSize: 10,

                                color:
                                  this.collectLiveImpressionString(z.orderid) >
                                  0
                                    ? "#f00"
                                    : "#000",
                              }}
                            >
                              LIVE
                            </div>
                            <div style={{ width: "80%" }}>
                              <ProgressBar
                                labelAlignment="center"
                                labelColor="#fff"
                                bgcolor="#ff5722"
                                completed={parseInt(
                                  (this.collectLiveImpressionString(z.orderid) /
                                    (z.targettoday === 0 ? 1 : z.targettoday)) *
                                    100
                                )}
                                width="100%"
                              />
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}
export default withRouter(ClientPage);
